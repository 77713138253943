import React from 'react';

export default ({ width = 652, height = 1015, style = {} }) => (
  <svg
    id="dt-ap"
    className="absolute block"
    width={width}
    height={height}
    style={style}
    viewBox="0 0 652 1015"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g>
      <path
        d="M326 572.535L69.0792 395.845C41.5551 376.939 25.0938 345.665 25.0938 312.271V38.49C25.0938 27.5352 37.3955 21.086 46.4227 27.2701L442.38 299.019C461.762 312.271 452.292 342.573 428.839 342.573H223.073C199.62 342.573 190.15 312.271 209.532 299.019L605.578 27.2701C614.605 21.086 626.907 27.5352 626.907 38.49V312.271C626.907 345.665 610.445 376.851 582.921 395.845L326 572.535Z"
        stroke="#C99CEF"
        strokeWidth="48"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="M326 572.535L69.0792 395.845C41.5551 376.939 25.0938 345.665 25.0938 312.271V38.49C25.0938 27.5352 37.3955 21.086 46.4227 27.2701L442.38 299.019C461.762 312.271 452.292 342.573 428.839 342.573H223.073C199.62 342.573 190.15 312.271 209.532 299.019L605.578 27.2701C614.605 21.086 626.907 27.5352 626.907 38.49V312.271C626.907 345.665 610.445 376.851 582.921 395.845L326 572.535Z"
        stroke="url(#dt-ap-paint0_linear)"
        strokeWidth="48"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
    </g>
    <g>
      <path
        d="M262.775 990.076C262.853 947.672 262.93 864.559 262.93 864.559C262.93 864.559 245.065 864.404 220.859 864.404C196.189 864.404 191.163 855.923 203.304 835.261C238.724 774.969 274.53 714.909 310.723 655.08C321.473 637.347 330.985 635.728 342.74 655.542C378.469 715.68 414.275 775.74 449.54 836.186C460.831 855.461 455.727 863.942 432.99 864.636C409.558 864.636 389.528 864.636 389.528 864.636V990.539C389.528 990.539 485.114 990.539 540.951 990.539C606.299 989.614 644.657 931.944 619.987 873.04C617.281 866.486 614.033 860.087 610.398 853.919C543.348 740.197 476.221 626.553 408.707 513.217C401.902 501.729 393.781 490.241 383.805 481.375C344.132 445.832 281.49 454.313 253.727 500.341C180.568 621.31 107.95 742.665 36.4918 864.713C3.93355 920.302 41.0545 985.682 105.63 989.614C126.665 990.77 216.451 990.076 262.775 990.076Z"
        stroke="#C99CEF"
        strokeWidth="48"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="M262.775 990.076C262.853 947.672 262.93 864.559 262.93 864.559C262.93 864.559 245.065 864.404 220.859 864.404C196.189 864.404 191.163 855.923 203.304 835.261C238.724 774.969 274.53 714.909 310.723 655.08C321.473 637.347 330.985 635.728 342.74 655.542C378.469 715.68 414.275 775.74 449.54 836.186C460.831 855.461 455.727 863.942 432.99 864.636C409.558 864.636 389.528 864.636 389.528 864.636V990.539C389.528 990.539 485.114 990.539 540.951 990.539C606.299 989.614 644.657 931.944 619.987 873.04C617.281 866.486 614.033 860.087 610.398 853.919C543.348 740.197 476.221 626.553 408.707 513.217C401.902 501.729 393.781 490.241 383.805 481.375C344.132 445.832 281.49 454.313 253.727 500.341C180.568 621.31 107.95 742.665 36.4918 864.713C3.93355 920.302 41.0545 985.682 105.63 989.614C126.665 990.77 216.451 990.076 262.775 990.076Z"
        stroke="url(#dt-ap-paint1_linear)"
        strokeWidth="48"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
    </g>
    <defs>
      <linearGradient
        id="dt-ap-paint0_linear"
        x1="326"
        y1="24.8638"
        x2="326"
        y2="493.5"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#B1F8FC" />
        <stop offset="1" stopColor="#C99CEF" />
      </linearGradient>
      <linearGradient
        id="dt-ap-paint1_linear"
        x1="326.001"
        y1="561.334"
        x2="326.001"
        y2="990.539"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#C99CEF" />
        <stop offset="1" stopColor="#B1F8FC" />
      </linearGradient>
    </defs>
  </svg>
);
