import React from 'react';
import { useInView } from 'react-intersection-observer';

import StickyScroller from '../sticky-scroller';
import DTAP from './hero/dt-ap';
import isMobile from '../../utils/is-mobile';

export default () => {
  const [ref, inView] = useInView({
    threshold: 1,
    triggerOnce: true,
  });

  return (
    <StickyScroller>
      {({ scrollProgress, rect: { top, height } }) => {
        const offsetY = isNaN(scrollProgress) ? 0 : scrollProgress;

        const mobile = isMobile();
        const clusterProgress = mobile ? 0 : top < height && top > -height * 1.5 ? top : 0;

        return (
          <div className="relative bg-navy">
            <div
              className="absolute z-10 w-full pin-r bg-navy h-screen"
              style={{
                background: `linear-gradient(0deg, #061838 0%, #143673 100%)`,
              }}
            />
            <div className="relative z-10 h-screen">
              <div
                className="h-full"
                style={{
                  opacity: `${1 - offsetY}`,
                }}
              >
                <DTAP
                  width="100%"
                  height="115%"
                  style={{
                    top: '50%',
                    left: 0,
                    transform: 'translateZ(0) translate(-43%, -50%)',
                  }}
                />
              </div>
            </div>
            <div
              className="hero absolute z-10"
              style={{
                height: 'auto',
                top: '50%',
                left: '50%',
                transform: `translate(-50%, -50%)`,
              }}
            >
              <h1
                ref={ref}
                className={`heading-text ${
                  inView ? 'in-view' : ''
                }`}
                style={{
                  transition: `${offsetY ? 'none' : ''}`,
                  transform: 'translateZ(0)',
                  height: `${offsetY ? 'calc(2.5043478261em - ' + (offsetY * 2.5043478261) + 'em)' : ''}`,
                }}
              >
                <span>Paloma</span>
                <span>+ Afterpay</span>
              </h1>
              <div className="max-w-lg mx-auto">
                <p
                  className={`highlight-text text-white mx-3 mt-8 animated delay-4s ${
                    inView ? 'fadeIn' : 'opacity-0'
                  }`}
                >
                  This is the story behind Afterpay’s rise from start-up, to{' '}
                  <span className="text-special-red">$25 billion </span>
                  international juggernaut.
                </p>
              </div>
            </div>
            <div className="relative max-w-lg h-full mx-auto">
              {!mobile && <svg
                className={`absolute pin-b pin-r z-10 animated delay-4s ${
                  inView ? 'fadeInUpSmall' : 'opacity-0'
                }`}
                width="329"
                height="393"
                viewBox="0 0 329 393"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g transform="translate(0 120)">
                  <path
                    d="M250.869 136.925L259.52 96.5966L290.14 124.447L250.869 136.925Z"
                    fill="#B1F8FC"
                    transform={`translate(0 ${clusterProgress / 12})`}
                  />
                  <path
                    d="M58.1411 128.751L134.433 111.516L111.563 186.327L58.1411 128.751Z"
                    stroke="#B1F8FC"
                    strokeWidth="2"
                    transform={`translate(0 ${clusterProgress / 10})`}
                  />
                  <path
                    d="M308.167 172.934L301.847 178.044L300.554 169.988L308.167 172.934Z"
                    stroke="#FF5A7D"
                    strokeWidth="2"
                    transform={`translate(0 ${clusterProgress / 16})`}
                  />
                  <path
                    d="M79.7959 266.313L102.471 250.483L104.94 278.122L79.7959 266.313Z"
                    fill="#C99CEF"
                    transform={`translate(0 ${clusterProgress / 10})`}
                  />
                  <path
                    d="M218.283 209.006L212.226 204.095L219.476 201.325L218.283 209.006Z"
                    fill="#C99CEF"
                    transform={`translate(0 ${clusterProgress / 18})`}
                  />
                </g>
              </svg>}
            </div>
          </div>
        );
      }}
    </StickyScroller>
  );
};
