import React from 'react';

import Hero from '../../components/00-home/hero';

export default ({ id, className, forwardRef }) => {
  return (
    <div id={id} className={className} ref={forwardRef}>
      <Hero />
    </div>
  );
};
